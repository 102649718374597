<template>
  <div class="landinglist">
    <el-table
      v-loading="isLoading"
      :lazy="true"
      :data="TIRE_MODEL_LIST"
      style="width: 100%">
      <el-table-column
        label="Название"
        prop="name" />
      <el-table-column align="right">
        <template slot-scope="scopeData">
          <router-link
            :to="`/tire-models/${scopeData.row.id}`"
            @click="setTitle">
            <el-button type="primary">
              Подробнее
              <i class="el-icon-arrow-right" />
            </el-button>
          </router-link>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'LandingList',
  data() {
    return {
      isDisableSave: true,
      search: '',
    };
  },
  computed: {
    ...mapGetters(['TIRE_MODEL_LIST', 'TIRE_MODEL_REQUEST_STATUS']),
    isLoading() {
      return this.TIRE_MODEL_REQUEST_STATUS === 'requested';
    },
  },
  mounted() {
    this.setPageTitle('Модели шин');
  },
  methods: {
    ...mapMutations(['setPageTitle']),
    setTitle(val) {
      const pageTitle = this.TIRE_MODEL_LIST.find(({ id }) => id === Number(val.id)).name || 'Модель шины не найдена';
      this.setPageTitle(pageTitle);
    },
  },
};
</script>
